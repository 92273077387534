<template>
  <b-modal
    id="cancel_appointment_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
  >
    <template v-slot:modal-header="{ close }">
      <h3>{{ $t("cancelAppointmentModal.title") }}</h3>
      <button @click="close" id="cancel_appointment_modal_close_btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </template>
    <div class="bb-form-group">
      <label
        >{{ $t("cancelAppointmentModal.messageInput.label") }}
        <span class="label-description">{{
          $t("cancelAppointmentModal.messageInput.description")
        }}</span></label
      >
      <b-form-textarea
        rows="3"
        class="bb-textarea"
        v-model="form.fields.message"
      ></b-form-textarea>
      <div class="bb-invalid-feedback" v-show="form.errors.message">
        {{ form.errors.message }}
      </div>
    </div>
    <template v-slot:modal-footer>
      <button class="bb-btn btn-danger" @click="submit()">
        {{ $t("cancelAppointmentModal.submitBtn") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import cancelAppointmentForm from "@/core/services/forms/cancelAppointmentForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";

export default {
  name: "CancelAppointmentModal",
  mixins: [formMixin],
  beforeMount() {
    // Get form object from cancelAppointmentForm service
    this.form = JSON.parse(JSON.stringify(cancelAppointmentForm));
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
    },

    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#cancel_appointment_modal_close_btn").click();
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.submitForm()
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.closeModal();
          this.$store.dispatch(GET_PLACES);
        })
        .catch(error => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/cancel-appointment-modal.scss";
</style>
