import cancelAppointmentForm from "@/core/services/forms/cancelAppointmentForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";

export default {
  methods: {
    /**
     * Init cancelAppointmentForm with default field values
     * @param {Object} appointment
     * @return {void}
     */
    cancelAppointment(appointment) {
      let form = JSON.parse(JSON.stringify(cancelAppointmentForm));
      form.path = form.path + "/" + appointment.id;
      form.mode = "edit";
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.cancelAppointmentModal) {
        this.$refs.cancelAppointmentModal.show();
      }
    }
  }
};
