const cancelAppointmentForm = {
  /**
   * Appointment form key
   * @type {String}
   */
  key: "cancelAppointmentForm",

  /**
   * Appointment form fields object
   * @type {Object}
   */
  fields: {
    message: null
  },

  /**
   * Appointment form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Appointment form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Appointment form path
   * @type {String}
   */
  path: "/api/b2b/cancel/appointment",

  /**
   * Appointment form helpers
   * @type {Object}
   */
  helpers: {}
};

export default cancelAppointmentForm;
