import appointmentForm from "@/core/services/forms/appointmentForm.js";
import { OPEN_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";
import { disableBodyScroll } from "body-scroll-lock";

export default {
  methods: {
    /**
     * Init appointment form with default values from appointmentForm object and open appointment form element
     * @param {Object|null} fields
     * @return {void}
     */
    createAppointment(fields = null) {
      let form = JSON.parse(JSON.stringify(appointmentForm));
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          form.fields[key] = value;
        }
      }
      this.$store.dispatch(INIT_FORM, form);
      document.querySelector("#appointment_form_toggle").click();
      this.$store.dispatch(OPEN_ELEMENT, "appointment_form");
      let targetElement = document.querySelector("#appointment_form");
      disableBodyScroll(targetElement);
    },

    /**
     * Init appointment form with edit mode and open appointment form element
     * @param {Object} appointment
     * @return {void}
     */
    editAppointment(appointment) {
      let form = JSON.parse(JSON.stringify(appointmentForm));
      form.mode = "edit";
      form.path = form.path + "/" + appointment.id;
      form.fields.type = appointment.type;
      form.fields.clientId = appointment.client?.id ?? null;
      form.fields.date = appointment.date;
      form.fields.from = appointment.from;
      form.fields.notes = appointment.notes;
      form.fields.calendarId = appointment.calendarId;
      form.fields.serviceId = appointment.service?.id ?? null;
      form.fields.subjectId = appointment.subject?.id ?? null;
      form.fields.duration = appointment.duration;
      form.fields.clientIds = appointment.clients?.map(function(client) {
        return client.id
      }) ?? []
      form.fields._method = "PUT";
      form.helpers = {
        appointmentId: appointment.id,
        from: appointment.from
      };
      this.$store.dispatch(INIT_FORM, form);
      document.querySelector("#appointment_form_toggle").click();
      this.$store.dispatch(OPEN_ELEMENT, "appointment_form");
      let targetElement = document.querySelector("#appointment_form");
      disableBodyScroll(targetElement);
    }
  }
};
