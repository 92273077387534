const appointmentForm = {
  /**
   * Appointment form key
   * @type {String}
   */
  key: "appointmentForm",

  /**
   * Appointment form fields object
   * @type {Object}
   */
  fields: {
    type: "REGULAR",
    clientId: null,
    clientIds: [],
    date: null,
    serviceId: null,
    calendarId: null,
    sujectId: null,
    duration: null,
    from: null,
    notes: ""
  },

  /**
   * Appointment form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Appointment form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Appointment form path
   * @type {String}
   */
  path: "/api/b2b/appointments",

  /**
   * Appointment form helpers
   * @type {Object}
   */
  helpers: {}
};

export default appointmentForm;
